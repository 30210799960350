module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.9/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.12.3_gatsby-plugin-image@3.12.3_gatsby-plugin-sharp@5.12.3_gatsby-t_p3ysviylhtf5mnmqyfuubumfti/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://staging.seedx.us/graphql","develop":{"hardCacheData":true,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"debug":{"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"writeQueriesToDisk":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"type":{"Post":{"limit":null},"MediaItem":{"localFile":{"maxFileSizeBytes":100485760,"requestConcurrency":15,"excludeByMimeTypes":[]},"excludeFieldNames":["contentNodes","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":50,"requestConcurrency":10,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter WordPress Blog","short_name":"GatsbyJS & WP","start_url":"/","background_color":"#ffffff","theme_color":"#0b0d12","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"958ef13e9247608c5c727cb3b470b8d2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.12.0_babel-plugin-styled-components@2.1.4_gatsby@5.12.9_rea_i54crxqkpbvw2djxwtt6fg2sdy/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/@joyfulelement+gatsby-plugin-scroll-reveal@0.0.8_gatsby@5.12.9_react@18.2.0/node_modules/@joyfulelement/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":false,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"25% 50% 0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.12.9_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K3XQJZ5","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
